body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Quicksand", sans-serif;
}
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");

#root {
  background-color: #0d121e;
  font-family: "Quicksand", sans-serif;
  min-height: 100vh;
  font-size: calc(18px + 6 * (100vw) / (960));
}
